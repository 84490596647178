import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "carbon-and-the-updated-ibm-design-language"
    }}>{`Carbon and the updated IBM Design Language`}</h2>
    <p>{`The Carbon Design System is integrating the new IBM Design Ethos and Language. It represents a completely fresh approach to the design of all things at IBM.`}</p>
    <p>{`The Carbon team will be updating components and introducing new guidelines incrementally into the Carbon Design System over the next few months. You’ll notice new entries marked “experimental” being added all the time. Experimental components are not fully tested and/or vetted, but are available for designers to work with. Your feedback on these components is critical for the success of Carbon; please open a GitHub issue from the footer of any page on the Carbon site to let the team know what you think.`}</p>
    <h3 {...{
      "id": "faq"
    }}>{`FAQ`}</h3>
    <p><strong parentName="p">{`What is changing?`}</strong></p>
    <p>{`Carbon is the IBM Design System for digital products. The new IBM Design Language will define and guide everything designed by IBM, including software products, digital and traditional marketing, hardware, advertising, events, physical spaces, and more. The Carbon Design System is the digital manifestation of the Design Language.`}</p>
    <p><strong parentName="p">{`How do I ensure that my products are ready for this transition?`}</strong></p>
    <p>{`Keep building with Carbon! The best way to ensure a smooth transition is to adhere to the Carbon Design System guidelines, elements and components.`}</p>
    <p><strong parentName="p">{`How will teams adopt the new IBM Design Language?`}</strong></p>
    <p>The IBM Design core team is establishing an adoption and activation program. A new <a href="https://w3.ibm.com/design/essentials">IBM Design Essentials</a> course is available. Teams will receive either in-person or remote education before the first products using the new IBM Design Language are released. We'll share news on Slack, Connections, and in the IBM Design Town Hall.</p>
    <h3 {...{
      "id": "updates"
    }}>{`Updates`}</h3>
    <p><strong parentName="p">{`Complete:`}</strong>{` New IBM Design Language fonts, glyphs, and experimental grid have been integrated into Carbon.`}</p>
    <p><strong parentName="p">{`In Progress:`}</strong>{` More icons, colors, page geometry, `}<a parentName="p" {...{
        "href": "/experimental/accordion/code"
      }}>{`experimental components`}</a>{` and `}<a parentName="p" {...{
        "href": "/experimental/ui-shell/code"
      }}>{`UI shell`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      